import request from '@/utils/request'

// 查询客户列表数据
export function findCustomer(params) {
  return request({
    url: `/admin/customers`,
    method: 'get',
    params: params
  })
}

// 创建客户
export function createCustomerByAdmin(data) {
  return request({
    url: `/admin/customers`,
    method: 'post',
    data
  })
}

// 查询编辑客户表单数据
export function findCustomerFormByAdmin(id) {
  return request({
    url: `/admin/customers/${id}/form`,
    method: 'get'
  })
}

// 更新客户
export function updateCustomerByAdmin(id, data) {
  return request({
    url: `/admin/customers/${id}`,
    method: 'put',
    data
  })
}

// 更新客户有效性
export function updateCustomerEffectiveByAdmin(id, data) {
  return request({
    url: `/admin/customers/${id}/effective`,
    method: 'patch',
    data
  })
}

// 解除客户微信绑定
export function relieveCustomerByAdmin(id) {
  return request({
    url: `/admin/customers/${id}`,
    method: 'patch'
  })
}
