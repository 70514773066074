<template>
  <div class="se-card-box">
    <search @submit="submitSearch" />

    <div class="se-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showNewModal"
        >新增
        </a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      size="middle"
      @change="sortChange"
    >
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a v-if="record.is_binding" class="se-red-link-color" @click="unbind(record.id)">解绑</a>
          <a @click="showEditModal(record.id)">编辑</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      :total-count="pagination.total_count"
      @change="fetchData"
    />

    <!-- 新增 模态框 -->
    <new
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑 模态框 -->
    <edit
      v-if="isShowEditModal"
      :id="editingId"
      :visible.sync="isShowEditModal"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import Search from '@/views/customers/Search'
import New from '@/views/customers/New'
import Edit from '@/views/customers/Edit'
import { findCustomer, relieveCustomerByAdmin, updateCustomerEffectiveByAdmin } from '@/api/customer'
import { hasPermission } from '../../utils/permission'
import { alertMessage } from '../../utils/alert_message'

export default {
  name: 'List',
  components: {
    Search,
    Pagination,
    New,
    Edit
  },
  data() {
    return {
      data: [],
      loading: true,
      // 是否显示新增表单弹窗
      isShowNewModal: false,
      // 是否显示编辑表单弹窗
      isShowEditModal: false,
      // 正在修改有效性的记录id
      editingEffectiveId: 0,
      // 正在编辑的记录id
      editingId: 0,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: 'created_at',
        sort_order: 'descend'
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '客户名称',
          dataIndex: 'enterprise_name',
          ellipsis: true
        },
        {
          title: '设备数',
          dataIndex: 'device_num',
          sorter: true,
          sortOrder: this.sort.sort_field === 'device_num' ? this.sort.sort_order : false
        },
        {
          title: '登录账号',
          dataIndex: 'username',
          ellipsis: true
        },
        {
          title: '联系人',
          dataIndex: 'full_name',
          ellipsis: true
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number'
        },
        {
          title: '邮箱',
          dataIndex: 'email'
        },
        {
          title: '微信账号',
          dataIndex: 'nick_name',
          ellipsis: true
        },
        {
          title: '状态',
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          width: 120,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    isHasPermission(permission) {
      if (hasPermission(permission)) {
        return true
      } else {
        alertMessage({
          type: 'error',
          content: '您没有该操作的权限'
        })
        return false
      }
    },

    showNewModal() {
      if (this.isHasPermission('admin/customers/new')) {
        this.isShowNewModal = true
      }
    },

    showEditModal(id) {
      if (this.isHasPermission('admin/customers/edit')) {
        this.editingId = id
        this.isShowEditModal = true
      }
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findCustomer(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      if (this.isHasPermission('admin/customers/effective')) {
        const vm = this
        const effective = checked

        this.$confirm({
          title: effective ? '确定将账号生效吗?' : '确定失效账号吗?',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            vm.editingEffectiveId = record.id
            updateCustomerEffectiveByAdmin(record.id, { effective: effective }).then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            }).catch(() => {
              vm.editingEffectiveId = 0
            })
          }
        })
      }
    },

    unbind(id) {
      if (this.isHasPermission('admin/customers/relieve')) {
        const vm = this
        this.$confirm({
          title: '确定解除当前微信账号与此账号的关联吗？',
          content: '',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            relieveCustomerByAdmin(id).then((res) => {
              if (res.code === 0) {
                vm.fetchData()
              }
            })
          }
        })
      }
    }
  }
}
</script>
